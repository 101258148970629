<template>
  <div class="main">
    <navbart @asideShow="asideFlagFun"></navbart>
    <div class="barrier" style="margin-top: 30px" v-if="flag">
      <p class="barrierTil">{{$t("answer.miningNum")}}</p>
      <div class="cishuB">
        {{$t("answer.miningSpeed")}}({{getAnswerjd.userAnswerQuestions.length == 0 ?'0':'1'}}/1) {{$t("answer.toolbox")}} &nbsp;<svg-icon
          icon-class="bi"
          style="font-size: 15px; color: white"
        ></svg-icon>
      </div>
      <van-button round color="#F8CE17" @click="toAnswer(1)">
        <span style="color: black; font-size: 12px">{{$t("answer.AddDraft")}}</span>
      </van-button>
    </div>
    <div class="barrier stas" v-if="flag">
      <p class="stasTil">{{$t("answer.Addmining")}}</p>
      <div class="cishuB" style="background:#734DEF;">
        {{$t("answer.miningSpeed")}}({{getAnswerjd.challengeAnswer.length == 0 ?'0':'1'}}/1) {{$t("answer.toolbox")}}  &nbsp;
        <van-image
          width="14px"
          height="16px"
          :src="require('@/assets/xxImage/index/jiangli.png')"
        ></van-image>
      </div>
      <van-button round color="#F8CE17" @click="toAnswer(2)">
        <span style="color: black; font-size: 12px">{{$t("answer.AddDraft")}}</span>
      </van-button>
    </div>
    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>

<script>
import navbart from "@/components/common/navbar.vue";
import asidePopup from "@/components/common/asidePopup";
export default {
  components: { navbart, asidePopup },
  data() {
    return {
      asideFlag: false,
      getAnswerjd: null,
      flag:false, //解决created异步 数据延迟报错问题
      userInfo:null,
    };
  },
  created() {
    this.getAnswer();
    this.getInfo()
  },
  methods: { 
    getAnswer() {
      this.$axios.get(this.$api.answerSchedule).then((res) => {
        this.getAnswerjd = res.data;
        this.flag = true
      });
    },
    getInfo(){
      this.$axios.get(this.$api.userinfo).then(res => {
        this.userInfo = res.data
      })
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    toAnswer(num) {
      if (num == 1) {
        if(this.userInfo.active != 1 ){
            this.$toast(this.$t("index.userFirst"));
          return
        }
        if(this.getAnswerjd.userAnswerQuestions.question_progress || this.getAnswerjd.userAnswerQuestions.question_progress == 10){
          this.$toast(this.$t("answer.card"));
          return
        }
          this.$router.push("/answerPage");
      }
      if (num == 2) {
        if (this.getAnswerjd.challengeAnswer.length == 0) {
          this.$router.push("/answerChallenge");
        } else {
          this.$toast(this.$t("answer.card"));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh;
  background: url("../assets/xxImage/index/datibg.png") no-repeat;
  background-size: 100% 100%;
  padding: 14px;
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.stas {
  background: url("../assets/xxImage/index/dati1.png") no-repeat !important;
  background-size: 100% 100% !important;
  .stasTil {
    font-size: 30px;
    font-family: FZCuYuan-M03S;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    -webkit-text-stroke: 1px #5029cb;
    text-shadow: 1px 1px 0px #5029cb, -1px 1px 0px #5029cb, 1px -1px 0px #5029cb,
      -1px -1px 0px #5029cb;
  }
}
.barrier {
  width: 100%;
  height: 170px;
  background: url("../assets/xxImage/index/dati2.png") no-repeat;
  background-size: 100% 100%;
  padding: 36px 17px;
  .barrierTil {
    font-size: 30px;
    font-family: FZCuYuan-M03S;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    -webkit-text-stroke: 1px #335bd9;
    text-shadow: 1px 1px 0px #335cd9, -1px 1px 0px #335cd9, 1px -1px 0px #335cd9,
      -1px -1px 0px #335cd9;
  }
  .cishuB {
    max-width: 184px;
    height: 24px;
    background: #5178f1;
    border-radius: 50px;
    text-align: center;
    line-height: 27px;
    color: white;
    font-size: 12px;
    margin-top: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    width: 87px;
    height: 32px;
    border: 1px solid #000 !important;
    margin-top: 9px;
  }
}
</style>